import classNames from 'classnames';
import * as React from 'react';
import { Container, ContainerProps } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { createUseStyles } from 'react-jss';
import { webTitlePrefix } from '../../../common/configuration/paths';
import Navbar from '../Navbar/Navbar';
import style from './Page.style';

export type PageProps = {
    title: string,
    headerText?: string,
    headerNote?: string,
    fluid?: ContainerProps['fluid'],
};

export const DEFAULT_PAGE_PROPS = {
    headerText: '',
    headerNote: 'Ihre WMS Agentur',
} as const;

const useStyle = createUseStyles(style);

const Page: React.FC<PageProps> = (props) => {
    const { title, headerText, headerNote, children, fluid = false } = { ...DEFAULT_PAGE_PROPS, ...props };
    const classes = useStyle();
    return (
        <>
            <Helmet>
                <title>{`${webTitlePrefix}${title}`}</title>
            </Helmet>
            <Container fluid className={classNames("px-0 d-flex flex-column", classes.pageContainer)}>
                <Container fluid className={classNames('mb-4 pb-0 px-0', classes.navContainer)}>
                    <Container className='font-weight-light p-0 p-xl-5 d-flex flex-column'>
                        <p className="display-1 text-center mt-4">Weather Monitoring System</p>
                        <p className="text-light lead py-3 px-3 px-xl-5">{headerText}</p>
                        <p className={classNames('h4 mx-3', classes.note)}>{headerNote}</p>
                    </Container>
                    <Navbar />
                </Container>
                <Container fluid={fluid}>
                    {children}
                </Container>
            </Container>
        </>
    )
}

export default Page;