import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { webPaths } from '../../../common/configuration/paths';
import style from './Navbar.style';
import { faUnlockAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

const useStyle = createUseStyles(style);

export type LinkedPage = {
    label: string,
    icon?: IconDefinition,
    target: string | URL,
}

export const LinkedPages: LinkedPage[] = [
    {
        label: 'Home',
        target: webPaths.home,
    },
    {
        label: 'Leistungen',
        target: webPaths.examples,
    },
    {
        label: 'Kontakt',
        target: webPaths.contact,
    },
    {
        label: 'Impressum',
        target: webPaths.impressum,
    },
    {
        label: 'Probe',
        target: webPaths.trial,
    },
    {
        label: 'Admin',
        icon: faUnlockAlt,
        target: webPaths.admin
    },
]

const Navbar = () => {
    const classes = useStyle();
    const links = React.useMemo(() => LinkedPages.map(({ label, icon, target }) => {
        const Wrapper = ({ children, className }: { children: React.ReactNode, className: string }) => (typeof target === 'string' ? <Link to={target} className={className}>{children}</Link> : <a href={target.href} className={className}>{children}</a>);
        return (
            <Wrapper key={label} className={classNames(classes.link, "text-white text-decoration-none")}>
                <div className="d-flex flex-row justify-content-center align-items-center py-2 px-3">
                    {icon !== undefined ? (<FontAwesomeIcon icon={icon} />) : null}

                    <p className={classNames("py-0 my-0", icon !== undefined ? 'mx-1' : undefined)}>{label}</p>
                </div>
            </Wrapper>
        )
    }), [classes]);
    return (
        <div className={classNames("d-flex flex-row justify-content-center align-items-stretch w-100 flex-wrap", classes.bar)}>
            {links}
        </div>
    );
}

export default Navbar;
