import classNames from 'classnames';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Page from '../../components/Page/Page';
import { headerText, paragraphs } from './Home.translation';
import style from './Home.style';
import { webTitles } from '../../../common/configuration/paths';

const useStyle = createUseStyles(style);

const Home = () => {
    const startParagraphs = React.useMemo(() => {
        return paragraphs.start.map(text => <p key={text.slice(0, 10)}>{text}</p>);
    }, [paragraphs.start]);
    const classes = useStyle();
    return (
        <Page
            title={webTitles.home}
            headerText={headerText}
        >
            {startParagraphs}
            <img src="https://kunden.viwetter.de/cached" alt="Globe of cached weather data" className={classNames("mb-3", classes.globe)} />
            {paragraphs.end}
        </Page>
    );
}

export default Home;