import * as React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../configuration/theme";
import * as Color from 'color';
import { useWindowWidth } from "@react-hook/window-size";

const LEFT_PANEL_DEFAULT_WIDTH_PX = 400;
const DEFAULT_VIEW_PROPS = {
    themePrimary: theme.gradient.startColor,
    themeSecondary: theme.gradient.endColor,
    footerColor: '#333c',
    leftPanelWidth: `${LEFT_PANEL_DEFAULT_WIDTH_PX}px`,
    fit: false,
} as const;

export type ViewProps = {
    title: string,
    subtitle?: string,
    leftPanel?: React.ReactNode,
    leftPanelWidth?: string,
    topLeftIconURL?: URL,
    topRightIconURL?: URL,
    bottomLeftIconURL?: URL,
    themePrimary?: string,
    themeSecondary?: string,
    footerColor?: string,
    /** Iff true letterbox and scale to width and height */
    fit?: boolean,
    width?: number,
    height?: number,
}

const FOOTER_HEIGHT_PX = 75;
const BANNER_HEIGHT_PX = 200;
const VERTICAL_SCROLLER_BREAKPOINT_WIDTH_PX = LEFT_PANEL_DEFAULT_WIDTH_PX * 2;
const IMAGE_BORDER_PADDING_PX = 10;

type JssProps = Partial<ViewProps> & { hasChildren: boolean, isScroller: boolean };
const useStyle = createUseStyles({
    view: (props: JssProps) => ({
        background: `linear-gradient(180deg, ${props.themePrimary}, ${props.themeSecondary})`,
        ...(props.isScroller ? {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            overflowX: 'hidden',
        } : {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }),
    }),
    footer: (props: JssProps) => ({
        height: `${FOOTER_HEIGHT_PX}px`,
        backgroundColor: props.footerColor || 'inherit',
        color: new Color(props.footerColor).isLight() ? 'black' : 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        ...(!props.isScroller && {
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
        }),
    }),
    banner: (props: JssProps) => ({
        height: `${BANNER_HEIGHT_PX}px`,
        backgroundColor: props.themePrimary || 'inherit',
        color: new Color(props.themePrimary).isLight() ? 'black' : 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        ...(!props.isScroller && {
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
        }),
    }),
    leftPanel: (props: JssProps) => ({
        visibility: !!props.leftPanel ? 'visible' : 'hidden',
        backgroundColor: new Color(props.themePrimary).darken(0.1).toString(),
        color: new Color(props.themePrimary).isLight() ? 'black' : 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',

        ...(!props.isScroller && {
            position: 'fixed',
            left: 0,
            top: `${BANNER_HEIGHT_PX}px`,
            bottom: `${FOOTER_HEIGHT_PX}px`,
            width: props.leftPanelWidth,
        }),
    }),
    content: (props: JssProps) => ({
        boxShadow: '0px 0px 10px #3333 inset',
        color: new Color(props.themePrimary).mix(new Color(props.themeSecondary)).isLight() ? 'black' : 'white',

        ...(props.isScroller ? {
            flexGrow: 1,
            maxWidth: '100vw',
        } : {
            position: 'fixed',
            overflow: "hidden",
            left: (!!props.leftPanel && !!props.leftPanelWidth) ? props.leftPanelWidth : '0px',
            right: 0,
            bottom: `${FOOTER_HEIGHT_PX}px`,
            top: `${BANNER_HEIGHT_PX}px`,
        }),
    }),
    bottomLeftIcon: (props: JssProps) => ({
        maxHeight: props.isScroller ? `${FOOTER_HEIGHT_PX - 2 * IMAGE_BORDER_PADDING_PX}px` : `${(FOOTER_HEIGHT_PX - IMAGE_BORDER_PADDING_PX) * 2}px`,
        marginTop: props.isScroller ? '' : `-${FOOTER_HEIGHT_PX}px`,
        maxWidth: '25vw',
        paddingLeft: `${IMAGE_BORDER_PADDING_PX}px`,
    }),
    contactInfo: {
        display: 'flex',
        paddingRight: `${IMAGE_BORDER_PADDING_PX}px`,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
    },
    timestring: {
        paddingLeft: `${IMAGE_BORDER_PADDING_PX}px`,
        paddingRight: `${IMAGE_BORDER_PADDING_PX}px`,
        textAlign: 'center',
    },
});

const getCurrentTimeString = () => {
    return new Date().toLocaleString();
}

const View: React.FC<React.PropsWithChildren<ViewProps>> = (props) => {
    const {
        title,
        subtitle,
        leftPanelWidth,
        themePrimary,
        themeSecondary,
        children,
        footerColor,
        leftPanel,
        bottomLeftIconURL,
    } = { ...DEFAULT_VIEW_PROPS, ...props };
    const windowWidth = useWindowWidth();
    const [currentTimestring, setCurrentTimestring] = React.useState(getCurrentTimeString);
    const currentTimestringIntervalRef = React.useRef<null | number>(null);
    const classes = useStyle({
        leftPanel, leftPanelWidth, themePrimary, themeSecondary, footerColor,
        hasChildren: React.Children.count(children) !== 0,
        isScroller: windowWidth < VERTICAL_SCROLLER_BREAKPOINT_WIDTH_PX,
    });

    React.useEffect(() => {
        const intervalId = currentTimestringIntervalRef.current;
        if (intervalId !== null) {
            window.clearInterval(intervalId);
        }
        currentTimestringIntervalRef.current = window.setInterval(() => {
            setCurrentTimestring(getCurrentTimeString());
        }, 1000);

        return () => {
            const unmountId = currentTimestringIntervalRef.current
            if (unmountId !== null) {
                window.clearInterval(unmountId);
            }
        }
    }, []);

    return (
        <div className={classes.view}>
            <div className={classes.banner}>
                <h1 className="display-1">{title}</h1>
                <h2>{subtitle}</h2>
            </div>
            <div className={classes.content}>{children}</div>
            <div className={classes.leftPanel}>{leftPanel}</div>
            <div className={classes.footer}>
                {bottomLeftIconURL !== undefined ? <img src={bottomLeftIconURL.href} className={classes.bottomLeftIcon} /> : <div />}
                <h5 className={classes.timestring}>{currentTimestring}</h5>
                <div className={classes.contactInfo}>
                    <span>contact</span>
                    <span>info</span>
                </div>
            </div>
        </div>
    );
}

export default React.memo(View);
