import * as React from "react";
import { Link } from "react-router-dom";

export type ExamplePackageText = Readonly<{
    title: string,
    paragraphs: string[],
    bullets: string[],
}>

export type ExamplePackageProps = {
    /** Number of package, starting from 0, used for text replacement */
    index: number,
    /**
     * Replaces the following occurrences:
     * $NUM -> natural number of WLP based on index (e.g. index + 1)
     */
    text: ExamplePackageText,
};

const ExamplePackage: React.FC<ExamplePackageProps> = ({ index, text }) => {
    const titleId = React.useMemo(() => text.title.toLowerCase().replace(/[ \-_+]+/g, '-'), [text.title]);
    return (
        <div className="card mb-3 mb-md-5 mt-1" id={titleId}>
            <div className="card-header">
                <h2 className="display-6 d-block mb-0"><Link className="text-decoration-none text-dark" to={`#${titleId}`}>{`WMS-Leistungspaket ${index + 1}`}</Link></h2>
            </div>
            <div className="card-body">
                <div className="card-title mb-4">
                    <span className="h4">{text.title}</span>
                </div>
                {text.paragraphs.map(content => (<p className="card-text" key={content}>{content.replace(/\$NUM/g, (index + 1).toString())}</p>))}
            </div>
            <hr />
            <div className="card-body">
                <ul>
                    {text.bullets.map(bullet => <li key={bullet}>{bullet}</li>)}
                </ul>
            </div>
        </div>
    );
}

export default React.memo(ExamplePackage);
