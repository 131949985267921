export const webPaths = {
    home: '/',
    examples: '/bsp',
    contact: '/contact',
    impressum: '/impressum',
    trial: '/trial',
    admin: new URL('https://kunden.viwetter.de/resources/admin/index.html'),
} as const;

export const webTitleDefault = 'WMS';
export const webTitlePrefix = 'WMS: ';

export const webTitles = {
    home: 'Home',
    examples: 'Leistungen',
    contact: 'Kontakt',
    impressum: 'Impressum',
    trial: 'Probe',
    admin: 'Admin',
} as const;
