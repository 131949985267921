import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import Page from '../../components/Page/Page';
import * as translations from './Examples.translation';
import ExamplePackage from './ExamplePackage/ExamplePackage';
import { webTitles } from '../../../common/configuration/paths';
import PreviewImages from './PreviewImages/PreviewImages';

const Examples: React.FC = () => {
    const WLPS = React.useMemo(() => translations.WMSLPs.map((text, index) => (
        <div className="col-12" key={text.title}><ExamplePackage index={index} text={text} key={text.title} /></div>
    )), [])
    return (
        <Page
            title={webTitles.examples}
            headerText={translations.headerText}
        >
            <div
                className='row mx-xl-5'
            >
                <PreviewImages />
                {WLPS}
            </div>
        </Page>
    );
}

export default Examples;
