const OLD_DOMAIN = process.env.CANONICAL_OLD_URL || 'kunden.viwetter.de';
const OLD_BASE_URL = new URL(`https://${OLD_DOMAIN}/`);
const DEFAULT_USER_PREFIXES = {
    '5 day table': 'render/7f0c588e9f5c8aba83163a7f88fdf89d682cf946',
    'map': 'render/018c49f84bd1bb7bdfbb9ace3fcd8831c1cc30db',
} as const;
const THUMB_SUFFIX = '_thumb';

export const defaultThemeLinks = (cityName: string) => ({
    table: {
        thumb: new URL(`${DEFAULT_USER_PREFIXES['5 day table']}.${cityName}${THUMB_SUFFIX}.jpg`, OLD_BASE_URL),
        jpg: new URL(`${DEFAULT_USER_PREFIXES['5 day table']}.${cityName}.jpg`, OLD_BASE_URL),
        pdf: new URL(`${DEFAULT_USER_PREFIXES['5 day table']}.${cityName}.pdf`, OLD_BASE_URL),
        web: new URL(`/dtage/${cityName}/5`, OLD_BASE_URL),
    },
    map: {
        thumb: new URL(`${DEFAULT_USER_PREFIXES['map']}.${cityName}${THUMB_SUFFIX}.jpg`, OLD_BASE_URL),
        jpg: new URL(`${DEFAULT_USER_PREFIXES['map']}.${cityName}.jpg`, OLD_BASE_URL),
        pdf: new URL(`${DEFAULT_USER_PREFIXES['map']}.${cityName}.pdf`, OLD_BASE_URL),
        web: new URL(`/forecast/${cityName}`, OLD_BASE_URL),
    },
    cityName,
} as const)

export const CITIES = [
    'Berlin',
    'Braunschweig',
    'Frankfurt',
    'Hamburg',
    'Kühlungsborn',
    'Oslo',
    'New York',
    'Rostock',
    'Tokio',
] as const;
