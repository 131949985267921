import { Styles } from 'react-jss';
import theme from '../../configuration/theme';

export default {
    navContainer: {
        backgroundImage: `linear-gradient(${theme.gradient.rotate}, ${theme.gradient.startColor}, ${theme.gradient.endColor})`,
        minHeight: 'min(30em, 50vh)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        overflow: 'hidden',
    },
    note: {
        alignSelf: 'end',
        color: theme.gradient.authorColor,
    },
    pageContainer: {
        minHeight: '100vh',
        backgroundColor: 'white',
    }
} as const;
