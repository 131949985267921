import axios from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { Forecast5Response } from '../../server/owm/apiTypes';
import { POLICY_PARAM, SIGNATURE_PARAM, usePolicy } from './usePolicy';

export type Forecast5Props = { location: string };

const useForecast5 = (props: Forecast5Props) => {
    const location = React.useMemo(() => props.location || null, [props.location]);
    const [policy, signature] = usePolicy();

    return useQuery(`forecast5-${props.location}`, () => {
        const rootUrl = new URL(window.location.href);
        rootUrl.pathname = "";
        return axios.get<Forecast5Response>(
            new URL(`forecast5/${props.location}`, process.env['APP_URL'] || rootUrl).href,
            {
                params: {
                    [POLICY_PARAM]: policy,
                    [SIGNATURE_PARAM]: signature,
                }
            }
        ).then(({ data }) => data);
    }, {
        enabled: policy !== null && signature !== null && location !== null,
    })
}

export default useForecast5;
