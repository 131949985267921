import * as React from 'react';
import useForecast5 from "./useForecast5";
import { groupBy } from 'lodash';
import { groupByDateAtLocation } from '../../common/parse/temp';

const useForecast5Days = ({ location }: { location: string }) => {
    const forecast5 = useForecast5({ location });

    const forecast5Days = React.useMemo(() => {
        const { data } = forecast5;
        if (data === undefined) {
            return undefined;
        }
        const city = data.city;
        const days = groupByDateAtLocation(data.list, (entry) => entry.dt, () => city?.timezone || 0);
        return days;
    }, [forecast5.data]);

    return React.useMemo(() => ({
        isLoading: forecast5.isLoading,
        location,
        city: forecast5.data?.city,
        data: forecast5Days,
    } as const), [forecast5Days, location, forecast5.isLoading, forecast5.data?.city]);
};

export default useForecast5Days;
