import * as React from 'react';
import { BrowserRouter, HashRouter, MemoryRouter, Routes, Route } from 'react-router-dom';
import { webPaths } from '../common/configuration/paths';
import Contact from './pages/Contact/Contact';
import Examples from './pages/Examples/Examples';
import Home from './pages/Home/Home';
import Impressum from './pages/Impressum/Impressum';
import { QueryClient, QueryClientProvider } from 'react-query';
import Table from './views/weather/Table';
import Forecast5Table from './views/weather/Forecast5Table';

const queryClient = new QueryClient();

const WMS = () => {
    const Router = React.useMemo(() => {
        switch (process.env.ROUTER) {
            case 'memory':
                return MemoryRouter;
            case 'browser':
                return BrowserRouter;
            case 'hash':
            default:
                return HashRouter;
        }
    }, [process.env.ROUTER])
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Routes>
                    <Route path={webPaths.home} element={<Home />} />
                    <Route path={webPaths.examples} element={<Examples />} />
                    <Route path={webPaths.impressum} element={<Impressum />} />
                    <Route path={webPaths.contact} element={<Contact />} />
                    <Route path={'view'}>
                        <Route path={'testview'} element={<Table header={['a', 'b', 'c']} legend={['1', '2']} title="Testview" rows={[['1a', '1b', '1c'], ['2a', '2b', '2c']]} />} />
                        <Route path={'forecast5/:location'} element={<Forecast5Table />} />
                    </Route>
                </Routes>
            </Router>
        </QueryClientProvider>
    );
}

export default WMS;
