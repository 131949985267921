import { Forecast5City, Forecast5Entry } from '../../server/owm/apiTypes';

export type OwmDate = {
    /** Time zone when printing taken from environment */
    directDate: Date,
    /** Use *UTC* functions to get local values */
    offsetDate: Date,
    dt: number,
    dtOffset: number,
}

export type { Forecast5City, Forecast5Entry };

/**
 * @param dt Unix time (in seconds) from {@link Forecast5Entry}
 * @param dtOffset Time offset in seconds from UTC from {@link Forecast5City}
 */
export const parseDate = (dt: Forecast5Entry['dt'], dtOffset: Forecast5City['timezone']): OwmDate => ({
    directDate: new Date(dt * 1000),
    offsetDate: new Date((dt + dtOffset) * 1000),
    dt,
    dtOffset,
});

export const getTimeValuesAtLocation = (date: OwmDate) => {
    const d = date.offsetDate;

    return {
        /** Day of week (0=sunday, 1=monday, ...) */
        day: d.getUTCDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,

        hours: d.getUTCHours(),
        minutes: d.getUTCMinutes(),
        seconds: d.getUTCSeconds(),

        /** Calendar day number */
        date: d.getUTCDate(),
        /** Calendar month number */
        month: d.getUTCMonth() + 1,
        /** Calendar year number */
        year: d.getUTCFullYear(),
    } as const;
}

export const cmpOwmDate = (a: OwmDate, b: OwmDate) => a.dt - b.dt;

export const groupByDateAtLocation = <T>(data: T[], accessDt: (dataPoint: T) => Forecast5Entry['dt'], accessDtOffset: (dataPoint: T) => Forecast5City['timezone']): T[][] => {
    const sortedData = data.slice().sort((a, b) => accessDt(a) - accessDt(b));
    return sortedData.reduce(({ lastDataPoint, days }, dataPoint) => {
        if (lastDataPoint === null) {
            return { lastDataPoint: dataPoint, days: [[dataPoint]] };
        }

        const lastDataPointTime = getTimeValuesAtLocation(parseDate(accessDt(lastDataPoint), accessDtOffset(lastDataPoint)));
        const dataPointTime = getTimeValuesAtLocation(parseDate(accessDt(dataPoint), accessDtOffset(dataPoint)));

        if ((['date', 'month', 'year'] as const).map(member => lastDataPointTime[member] === dataPointTime[member]).every(Boolean)) {
            days.at(-1)?.push(dataPoint);
            return { lastDataPoint: dataPoint, days: days };
        }

        return { lastDataPoint: dataPoint, days: [...days, [dataPoint]] };
    }, { lastDataPoint: null, days: [[]] } as { lastDataPoint: null | T, days: T[][] }).days;
}
