import * as React from "react";
import { webTitles } from "../../../common/configuration/paths";
import Page, { DEFAULT_PAGE_PROPS } from "../../components/Page/Page";
import { EMAIL, TELNO } from "../../configuration/uniform";
import ContactTranslations from "./Contact.translations";

const Contact = () => {
    return (
        <Page
            title={webTitles.contact}
        >
            <p>{ContactTranslations.intro}</p>
            <div className="d-flex justify-content-center my-4">
                <div className="card col-12 col-md-6 col-xl-4">
                    <div className="card-header h5">{DEFAULT_PAGE_PROPS.headerNote}</div>
                    <div className="card-body">{ContactTranslations.lines.map(line => (<div key={line}>{line}</div>))}</div>
                    <hr className="py-0 my-0" />
                    <div className="card-body">
                        <div>{TELNO}: <a href={`tel:${ContactTranslations.telno.replace(/[^0-9+]/g, '')}`}>{ContactTranslations.telno}</a></div>
                        <div>{EMAIL}: <a href={`mailto:${ContactTranslations.mail}`}>{ContactTranslations.mail}</a></div>
                    </div>
                </div >
            </div>
        </Page >
    )
}

export default React.memo(Contact);
