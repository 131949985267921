import { useWindowWidth } from '@react-hook/window-size/throttled';
import { times } from 'lodash';
import * as React from 'react';
import { createUseStyles, Styles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import useForecast5 from '../../hooks/useForecast5';
import View, { ViewProps } from "../common/View"

export const COLUMN_WIDTH_PX = 200;
export const LEGEND_WIDTH_PX = 300;
export const TABLE_COLUMN_PARAM = 'c';

const styles = {
    table: {
        display: 'grid',
        gridTemplateColumns: ({ columnCount }) => `${LEGEND_WIDTH_PX}px repeat(${columnCount}, ${COLUMN_WIDTH_PX}px)`,
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
} as Styles<"table", { columnCount: number }>;

const useStyle = createUseStyles(styles);

export type TableProps = {
    title: ViewProps['title'],
    subtitle?: ViewProps['title'],
    header: React.ReactNode[],
    legend: React.ReactNode[],
    rows: React.ReactNode[][], // Array of rows
};

const Table: React.FC<TableProps> = ({
    title, subtitle, header, legend, rows,
}) => {
    const windowWidth = useWindowWidth();
    const columnCount = React.useMemo(() => Math.min(Math.floor((windowWidth - LEGEND_WIDTH_PX) / COLUMN_WIDTH_PX), 5, rows[0]?.length), [windowWidth]);
    const classes = useStyle({ columnCount });

    return (
        <View
            title={title}
            subtitle={subtitle}
            bottomLeftIconURL={new URL("https://kunden.viwetter.de/resources/logo_invert.png")}
        >
            <div className={classes.table}>
                <span></span>
                {times(columnCount, (i) => <span key={`header-${i}`}>{header[i]}</span>)}
                {times(rows.length, (i) => [legend[i], ...rows[i].slice(0, columnCount).map((value, j) => <span key={`row-${i}-${j}`}>{value}</span>)])}
            </div>
        </View>
    )
}

export default Table;
