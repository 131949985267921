import * as React from 'react';
import * as ReactDOM from 'react-dom';
import WMS from './WMS';

import 'bootstrap';
import './configuration/custom.scss';
import './configuration/overwrite.css';

const mainDiv = document.getElementById('wms-main-div');

ReactDOM.render(<WMS />, mainDiv)