import * as React from 'react';

export const headerText = 'Herzlich willkommen auf dem Internetportal "Weather Monitoring System" (WMS). Wer nach unternehmensspezifischen meteorologischen Lösungen für visualisierte Wettervorhersagen in seinem Unternehmen sucht, ist hier genau richtig. Das Team von WMS setzt seinen Schwerpunkt auf die Visualisierung von Wettervorhersagen und aktuellen Wetterzuständen für interessierte Unternehmen. Ihrem Unternehmen ist es wichtig, dass ihr unternehmensspezifisches Corporate Design und die damit verbundenen Alleinstellungsmerkmale bei der Visualisierung der meteorologischen Daten entsprechend eingebunden und nach außen hin wahrgenommen werden.'
export const paragraphs = {
    start: [
        'Egal, ob Ihr Unternehmen in der Hotelerie, in der Gastronomie, im Bauwesen. bei der Energieversorgung, im öffentlicher Dienst oder in anderen Bereichen angesiedelt ist: Überall, wo es eine Schnittmenge zwischen Kunden, besonderer unternehmerischer Werbung und meteorologsiche Informationsvermittlung gibt, sollten wir miteinander ins Gespräch kommen.',
        'Wenn wir Ihr Interesse geweckt haben bzw. Sie Fragen zur visualisierten meteorologischen Dienstleistung WMS haben, scheuen Sie es nicht, uns zu kontaktieren.',
    ],
    end: [
        <p key="source">Als Quelle unserer Visualisierungen nutzen wir Daten und Icons der <a href="https://openweathermap.org/">OpenWeatherMap.</a></p>
    ]
} as const;
