import * as React from 'react';
import { webTitles } from '../../../common/configuration/paths';
import Page from '../../components/Page/Page';
import ImpressumTranslations from './Impressum.translations';

const Impressum = () => (
    <Page
        title={webTitles.impressum}
    >
        <div className='h5'>{ImpressumTranslations.title}</div>
        <div>
            {ImpressumTranslations.address.map(line => <div key={line}>{line}</div>)}
        </div>
        <div>
            {`${ImpressumTranslations.taxLabel}: ${ImpressumTranslations.taxNum}`}
        </div>
    </Page>
)

export default React.memo(Impressum);