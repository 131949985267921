import { mean, sortBy, sum, uniq } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getTimeValuesAtLocation, parseDate } from '../../../common/parse/temp';
import { Forecast5Entry } from '../../../server/owm/apiTypes';
import useForecast5Days from '../../hooks/useForecast5Days';
import Table from './Table';

enum Combinator {
    Range,
    Average,
    Max,
    Min,
    Noon,
    Sum,
}

type Visualizer<T> = readonly [React.ReactNode, (data: Forecast5Entry) => T, Combinator, (combination: T) => React.ReactNode];

export const renderTemperature = (data: number) => `${Math.round(data)}°C`;
export const renderHumidity = (data: number) => `${Math.ceil(data)}%`;
export const renderClouds = (data: number) => `${Math.ceil(data)}%`;
export const renderVolume = (data: number) => `${Math.round(data)}mm`;
export const renderDistance = (data: number) => `${Math.round(data)}m`;
export const renderPrecipitation = (data: number) => `${Math.ceil(data * 100)}%`;
export const renderSelf: <T>(data: T) => T = (data) => data;

/**
 * TOOD render as components
 */
const values = [
    ['Temperature (min)', (data) => data.main.temp_min, Combinator.Min, renderTemperature] as Visualizer<number>,
    ['Temperature', (data) => data.main.temp, Combinator.Range, renderTemperature] as Visualizer<number>,
    ['Temperature (max)', (data) => data.main.temp_max, Combinator.Max, renderTemperature] as Visualizer<number>,
    ['Temperature (feels like)', (data) => data.main.feels_like, Combinator.Range, renderTemperature] as Visualizer<number>,
    ['Humidity', (data) => data.main.humidity, Combinator.Noon, renderHumidity] as Visualizer<number>,
    ['Clouds', (data) => data.clouds.all, Combinator.Noon, renderClouds] as Visualizer<number>,
    ['Rain volume', (data) => data.rain?.['3h'] ?? 0, Combinator.Sum, renderVolume] as Visualizer<number>,
    ['Snow volume', (data) => data.snow?.['3h'] ?? 0, Combinator.Sum, renderVolume] as Visualizer<number>,
    ['Visibility', (data) => data.visibility, Combinator.Range, renderDistance] as Visualizer<number>,
    ['Precipitation', (data) => data.pop, Combinator.Max, renderPrecipitation] as Visualizer<number>,
    ['Icon', (data) => <>{data.weather.map((w, num) => <img key={num} style={{ maxWidth: '40px' }} src={`http://openweathermap.org/img/wn/${w.icon}@4x.png`} />)}</>, Combinator.Noon, renderSelf] as Visualizer<JSX.Element>,
    ['Description', (data) => data.weather.map((w) => w.description).join(', '), Combinator.Noon, renderSelf] as Visualizer<string>,
] as const;

const dayToWeekday = (day: 0 | 1 | 2 | 3 | 4 | 5 | 6) => ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][day];

const Forecast5Table: React.FC = () => {
    const params = useParams<'location'>()
    const location = React.useMemo(() => params.location as string, [params.location])
    const { data: forecast5days, isLoading: forecast5IsLoading, city } = useForecast5Days({ location });

    if (forecast5IsLoading || !forecast5days || !location) {
        return null;
    }

    return (
        <Table
            header={forecast5days.map((v) => {
                const date = parseDate(v[0].dt, city?.timezone || 0);
                const localDate = getTimeValuesAtLocation(date);

                return `${dayToWeekday(localDate.day)} ${localDate.date}.${localDate.month}.${localDate.year}`;
            })}
            legend={values.map(([label]) => label)}
            title={location}
            subtitle={'Daily'}
            rows={values.map(([, getData, combinator, render], rowIndex) => forecast5days.map((points) => {
                const data = points.map((entry) => getData(entry));
                switch (combinator) {
                    case Combinator.Range:
                        if (typeof data[0] !== 'number') {
                            throw new Error(`Unexpected data type: ${typeof data[0]}, expected number`);
                        }
                        if (uniq(data).length === 1) {
                            return (
                                <span data-combinator="range" key={`range-${rowIndex}`}>
                                    {(render as Visualizer<number>[3])(data[0])}
                                </span>
                            )
                        }
                        return (
                            <span data-combinator="range" key={`range-${rowIndex}`}>
                                {(render as Visualizer<number>[3])(Math.min(...data as number[]))}
                                {' — '}
                                {(render as Visualizer<number>[3])(Math.max(...data as number[]))}
                            </span>
                        )
                    case Combinator.Min:
                        if (typeof data[0] !== 'number') {
                            throw new Error(`Unexpected data type: ${typeof data[0]}, expected number`);
                        }
                        return (
                            <span data-combinator="min" key={`min-${rowIndex}`}>
                                {(render as Visualizer<number>[3])(Math.min(...data as number[]))}
                            </span>
                        )
                    case Combinator.Max:
                        if (typeof data[0] !== 'number') {
                            throw new Error(`Unexpected data type: ${typeof data[0]}, expected number`);
                        }
                        return (
                            <span data-combinator="max" key={`max-${rowIndex}`}>
                                {(render as Visualizer<number>[3])(Math.max(...data as number[]))}
                            </span>
                        )
                    case Combinator.Average:
                        if (typeof data[0] !== 'number') {
                            throw new Error(`Unexpected data type: ${typeof data[0]}, expected number`);
                        }
                        return (
                            <span data-combinator="average" key={`average-${rowIndex}`}>
                                {(render as Visualizer<number>[3])(mean(data as number[]))}
                            </span>
                        )
                    case Combinator.Sum:
                        if (typeof data[0] !== 'number') {
                            throw new Error(`Unexpected data type: ${typeof data[0]}, expected number`);
                        }
                        return (
                            <span data-combinator="sum" key={`sum-${rowIndex}`}>
                                {(render as Visualizer<number>[3])(sum(data as number[]))}
                            </span>
                        )
                    case Combinator.Noon:
                        const distanceToNoon = (point: typeof points[number]) => {
                            const owmDate = parseDate(point.dt, city?.timezone || 0);
                            return Math.abs(12 - getTimeValuesAtLocation(owmDate).hours);
                        }
                        const noon = sortBy(points, distanceToNoon)[0];
                        const index = points.indexOf(noon);
                        const noonData = data[index];
                        return (
                            <span data-combinator="noon" data-noon-dt={noon.dt} data-noon-dt-utc={noon.dt_txt} data-hours-to-noon={distanceToNoon(noon)} key={`noon-${rowIndex}`}>
                                {(render as ((data: typeof noonData) => JSX.Element))(noonData)}
                            </span>
                        )
                };
            }))} />
    )
};

export default Forecast5Table;
