import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Policy } from '../../common/validation/policies';

export const POLICY_PARAM = 'p';
export const SIGNATURE_PARAM = 's';

export const usePolicy = () => {
    const [searchParams] = useSearchParams();

    const policyName = React.useMemo(() => searchParams.get(POLICY_PARAM) || null, [searchParams]);
    const signature = React.useMemo(() => searchParams.get(SIGNATURE_PARAM) || null, [searchParams]);

    return React.useMemo(() => [policyName, signature], [policyName, signature]) as readonly [Policy, string];
}
