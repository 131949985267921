import classNames from "classnames";
import * as React from "react";
import { Button } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { CITIES, defaultThemeLinks } from "./PreviewImages.consts";
import { SHOW_MORE_BUTTON_TEXT } from "./PreviewImages.translation";

const style = {
    sizeChanger: {
        maxHeight: ({ open }: { open: boolean }) => (open ? 'inherit' : '100px'),
        marginBottom: '5px',
        overflow: 'hidden',
        marginLeft: 0,
        marginRight: 0,
        position: 'relative',
    },
    whiteOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '30px',
        display: ({ open }: { open: boolean }) => open ? 'none' : 'inherit',
        background: 'linear-gradient(0deg, white, transparent)',
    }
};

const useStyle = createUseStyles(style);

const PreviewImageCard = ({ links, cityName, headline }: { links: Record<'web' | 'jpg' | 'pdf' | 'thumb', URL>, cityName: string, headline: string }) => (
    <div className='col-12 col-md-6 col-lg-4 mb-3'>
        <div className="card">
            <div className="card-header text-center">
                <strong>{cityName}: </strong><span>{headline}</span>
            </div>
            <a href={links.web.href} target="_blank">
                <img src={links.thumb.href} className='w-100' />
            </a>
            <div className="card-body text-center">
                <ul className="list-inline mb-0">
                    {
                        [
                            {
                                href: links.web.href,
                                title: 'WEB',
                            },
                            {
                                href: links.jpg.href,
                                title: 'JPG',
                            },
                            {
                                href: links.pdf.href,
                                title: 'PDF',
                            },
                        ].map(({ href, title }) => (
                            <li className="list-inline-item"><a href={href} target="_blank">{title}</a></li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
)

const PreviewImages = () => {
    const [open, setOpen] = React.useState(false);
    const toggle = React.useCallback(() => { setOpen(currentState => !currentState); }, []);
    const classes = useStyle({ open });
    const sizeChangerRef = React.useRef<HTMLDivElement>(null);

    const links = React.useMemo(
        () => CITIES
            .map(defaultThemeLinks),
        [],
    );

    const boxes = React.useMemo(() => links
        .flatMap(({ table, map, cityName }) => [
            <PreviewImageCard links={table} cityName={cityName} headline={'Tabelle'} />,
            <PreviewImageCard links={map} cityName={cityName} headline={'Karte'} />,
        ]), [links]);

    return (
        <div className="col-12 pb-4">
            <div className="">
                <div className={classNames('row', classes.sizeChanger)} ref={sizeChangerRef}>
                    {boxes}
                    <div className={classes.whiteOverlay} />
                </div>
                {!open && <Button onClick={toggle} className="w-100">{SHOW_MORE_BUTTON_TEXT}</Button>}
            </div>
        </div>
    );
}

export default PreviewImages;
