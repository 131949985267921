import { ExamplePackageText } from "./ExamplePackage/ExamplePackage";

export const headerText = 'Hier sehen Sie beispielhaft unsere Leistungen. Angemeldete Nutzer haben zusätzlich die Möglichkeit Logos, Texte und Farben zu personalisieren.';
export const starterText = [
    'Unser Angebot für Sie sind vier sehr unterschiedliche WMS-Leistungspakete (WMS-LP). Den vier Leistungspaketen gemein ist die automatisierte Bereitstellung von meteorologischen Informationen für das aktuelle Wetter in Ihrer Region sowie für Wettervorhersagen in den kommenden Tagen. Eine zweite Gemeinsamkeit unserer Leistungen ist es, Ihren spezifischen Wünschen und Ihrem eigenen, unverwechselbaren, unternehmensspezifischen Corporate Design entsprechend, meteorologische interessante Informationen für Ihre Kunden zu visualisieren. Dabei können die bereitgestellten Wetterinformationen primär für Ihre Kunden selbst genutzt werden oder aber als zusätzliche, informative Marketingstrategie ihrer eigenen Leistungen und Produkte angesehen werden. Das wiederum generiert einen Mehrwert als auch ein zusätzliches Alleinstellungsmerkmal für Ihre Firma bzw. Unternehmung. Haben Sie mehr als einen Standort landes-, bundes- oder europaweit und möchten unsere Wettervisualisierungen an allen unterschiedlichen Standorten nutzen? Kein Problem. Sie können die Leistungspakete je nach Bedarf kombinieren, um so eine für Sie optimale Lösung Ihres Problems zu erhalten.',
    'Kontaktieren Sie uns einfach und lassen Sie sich kostenlos und fachkompetent von uns beraten. Wenn wir Ihr Interesse geweckt haben und Sie wie wir der Meinung sind, dass unsere visualisierten meteorologischen Lösungen Ihre Unternehmensstrategie bereichert, stellen wir Ihnen sehr gern zusätzlich kostenfreie Lösungen zum Kennenlernen zur Verfügung.',
] as const;
export const summaryHeader = 'Kurz zusammengefasst';
export const points = [
    {
        text: 'Es gibt vier WMS-Leistungspakete (WMS-LP) für Ihre Problemlösung:', points: [
            { text: 'Automatisierte Wetterinformationsanzeige im unternehmensspezifischen Design', },
            { text: 'Automatisierte Wetterinformationen für Ihre Homepage (Einbindung ins CMS-System)', },
            { text: 'Täglicher Wetterbericht tabellarisch oder/und als Regionallandkarte via E-Mail', },
            { text: 'Abonnierbare Live Wetterprognosen ICS Kalender', },
        ]
    },
    { text: 'Automatisierte Bereitstellung von meteorologischen Informationen – stündlich', },
    { text: 'Alle Lösungen sind separat oder kombinierbar erhältlich', },
    { text: 'Symbiose von Wetter- und unternehmensspezifischen Kundeninformationen', },
    { text: 'Beratung kostenfrei', },
    { text: 'Kundenspezifische Einrichtung kostenfrei', },
] as const;
export const WMSLPs: readonly ExamplePackageText[] = [
    {
        title: 'Automatisierte Wetterinformationsanzeige im unternehmensspezifischen Design',
        paragraphs: [
            'Das Wetter-Monitoring-System Leistungspaket 1 (WMS-LP 1) beinhaltet vollautomatisch generierte Wettergrafiken mit allen für Sie wichtigen Informationen rund ums Wetter. Einzige Voraussetzung ist nur der WEB-Zugang über einen Internet-Browser. Die Wettergrafiken werden in einer von Ihnen gewünschten Reihenfolge auf Ihrem Kundenmonitor oder/ und im Hotelkanal präsentiert. Zusätzlich können Sie eigene Grafiken, die Sie auf Ihrer Homepage sichtbar für Kunden oder nicht sichtbar für unternehmensinterne Aufgaben hinterlegt haben, in Ihre Visualisierung unkompliziert einbinden. Neben den eigenen und den meteorologischen Informationsgrafiken können Sie zusätzlich Filmspots bis 20 Sekunden Länge in diesem WMS-System platzieren. Das ermöglicht Ihnen ganz neue Möglichkeiten, das unternehmensspezifische Marketing mit Hilfe von Wetterinformationen Ihren Kunden schnell, effektiv und persönlich näher zu bringen.',
            'Je nachdem, welche Inhalte Sie visualisieren, setzen Sie damit eigene Akzente und kommunizieren gezielte Informationen für Ihre Kunden bzw. Mitarbeiter. Das eigens auf Ihre Unternehmung abgestimmte Layout unterstützt zusätzlich Ihr Alleinstellungsmerkmal.',
        ],
        bullets: [
            'Voraussetzung: nur WEB-Zugang über einen Internet-Browser',
            'Automatisch generierte Wettergrafiken mit Informationen rund ums Wetter – stündlich aktuell',
            'Zusätzlich eigene Informationsgrafiken (Filmsequenz bis 20 sec.) implementierbar',
            'Alle visualisierten Informationen sind mit Ihrem unternehmensspezifischen Layout unterlegt',
            'Kombinierbar mit anderen Leistungspaketen',
            'Beratung kostenfrei',
            'Kundenspezifische Einrichtung kostenfrei',
        ]
    },
    {
        title: 'Automatisierte Wetterinformationen für Ihre Homepage',
        paragraphs: [
            'Alle verfügbaren Wetterinformationen können auch automatisiert auf Ihrer Homepage eingebunden werden. Dafür stehen Ihnen sowohl textbasierte als auch tabellarische Ausgabeformate zur Verfügung. Wenn Sie LP1 und LP2 kombinieren, können Sie alle meteorologische Daten Ihrer Wetterinformationstafel (LP1) problemlos und Stunden aktuell ebenfalls auf Ihrer Webseite präsentieren.',
        ],
        bullets: [
            'Einbindung von Wetterinformationen auf Ihre Homepage',
            'Bereitstellung textbasierter als auch tabellarischer Ausgabeformate',
            'Kundenspezifische Einrichtung auf Ihrer Webseite meist durch Provider',
            'Beratung kostenfrei',
        ],
    },
    {
        title: 'Täglicher Wetterbericht tabellarisch oder/und als Regionallandkarte via E-Mail',
        paragraphs: [
            'Oftmals ist der Wunsch vorhanden, Informationen rund ums Wetter nicht ausschließlich digitalisiert, sondern „analog“ zum Anfassen an einer Pinnwand oder ähnlichem z.B. anzubieten. Dann ist die Lösung LP3. Die Ihnen täglich zugesandten Wetterinformationen können Sie problemlos ausdrucken und gezielt und unkompliziert an jedem beliebigen Ort öffentlich machen.',
        ],
        bullets: [
            'Tägliche Wetterinformationen tabellarisch oder/und als Regionallandkarte via E-Mail',
            'Ausdrucken von Wetterinformationen problemlos',
            'Öffentlich machen von Wetterinformationen im hauseigenen Design an jedem beliebigen Ort',
            'Beratung kostenfrei',
        ]
    },
    {
        title: 'Abonnierbare Live Wetterprognosen ICS Kalender',
        paragraphs: [
            'Wenn Sie möchten, das wir für Sie die Observierung in Sachen Wetter übernehmen, kein Problem. Wir geben Ihnen Bescheid, wenn z.B. der Wind stürmisch wird oder ein Gewitter naht, wenn Starkniederschläge sich ankündigen oder Sie einfach nur informiert werden wollen über Ihre eigenen persönlich wichtigen meteorologischen Schwellendaten.',
        ],
        bullets: [
            'Benachrichtigung bei besonderen meteorologischen Ereignissen via ICS Kalender',
            'Beratung kostenfrei',
        ]
    }
];